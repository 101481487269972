<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1 class="secondary--text">403</h1>
        <p class="subtitle-1 primary--text">Forbidden</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Forbidden'
}
</script>

<style scoped>
</style>
